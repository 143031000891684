import * as Turbo from "@hotwired/turbo";
import { Application, Controller } from "@hotwired/stimulus";

window.Turbo = Turbo;
window.Stimulus = Application.start();

class HelloController extends Controller {
  static classes = ["display"];
  static targets = ["menu", "menuMobile"];
  showMenu() {
    this.menuTarget.classList.toggle("hidden");
    this.menuMobileTarget.classList.toggle("hidden");
    this.menuMobileTarget.classList.toggle("block");
  }
}
Stimulus.register("hello", HelloController);
